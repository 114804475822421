import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import Menu from "app/layout/menu/";
import {
  MDBContainer,
  MDBBox,
  MDBHamburgerToggler,
  MDBLink,
  MDBCol,
  MDBRow, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
} from "mdbreact";
import { HeaderLogo } from "./header-bottom-query-container";
import Search from "app/layout/search";
import QuotationList from "app/layout/quotationList/quotationList";
import WishlistFront from "app/layout/wishlistFront/wishlistFront";
import MobileNav from "../mobileNav";
import Icon from "app/assets/icon/icon";
import {HeaderLogin, HeaderRegister, HeaderUserIcon} from "../top/header-top-query-container";
import {useDispatch} from "react-redux";
import {customerActions} from "../../../state/redux/data/customer";
import ModalWrapper from "../../login/modalWrapper";
import GetCart from "../top/getCart";

function useOutsideAlerter(ref, displaySearch, setDisplaySearch) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      event.target.tagName !== "svg" &&
      event.target.parentElement?.id !== "search-text" &&
      event.target.tagName !== "path"
    ) {
      if (displaySearch) {
        setDisplaySearch(!displaySearch);
      }
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

const Bottom = ({ menuItems }) => {
  let searchRef = useRef(null);
  let [searchVal, setSearchVal] = useState("");
  let [displaySearch, setDisplaySearch] = useState(false);

  useOutsideAlerter(searchRef, displaySearch, setDisplaySearch);
  const { isLoggedIn, customer } = useCustomer();
  const [open, setOpen] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    if(showMobileMenu) {
      document.getElementById("hamburger1").click();
    }
  };
  const isMobile = useMediaQuery({ maxWidth: 991.98 });
  useOutsideAlerter(searchRef, displaySearch, setDisplaySearch);
  
  const toggleHamburger = () => {
    setOpen(!open);
  };

  const closeBurger = () => {
    if (open) {
      document.getElementById("hamburger1").click();
    }
  };

  // const { isLoggedIn, customer } = useCustomer();
  const dispatch = useDispatch();
  const logOut = () => dispatch(customerActions.logoutCustomer());

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [activeItem, setActiveItem] = useState("1");
  const toggleTabs = (tab) => {
    if (activeItem !== tab) {
      setActiveItem(tab);
    }
  };
  return (
    <>
      <MDBContainer className={`bottom-header-wrapper`}>
        <MDBBox className="bottom-header d-flex align-items-center">
          <MDBBox className="left-part d-flex align-items-center">
            <MDBLink to="/" className={`p-0`}>
              {(!isMobile && <HeaderLogo />) || (
                <div className="header-logo-mobile-new">
                  <HeaderLogo />
                </div>
              )}
            </MDBLink>
            <MDBBox className="pl-5 mobile-menu-outer">
              <Menu menuItems={menuItems} />
            </MDBBox>
          </MDBBox>
          <MDBBox className="top-header d-flex align-items-center">

          </MDBBox>
          <MDBBox className="right-part d-flex align-items-center ">
            <MDBCol style={{paddingRight: "0", paddingBottom: "10px"}} className="top-header d-flex align-items-center">
              {isLoggedIn ? (
                  <>
                    <HeaderUserIcon />
                    <MDBDropdown style={{marginLeft:"1rem !important"}} size="lg">
                      <MDBDropdownToggle
                          caret
                          color="transparent"
                          className="user-dropdown"
                      >
                        <MDBLink className="p-0 text-capitalize d-inline">
                          {customer.firstname}
                        </MDBLink>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu basic>
                        <MDBDropdownItem>
                          <MDBLink
                              className="text-muted text-capitalize p-0"
                              to="/customer"
                          >
                            Account Dashboard
                            {/* <HeaderAccountDashboard /> */}
                          </MDBLink>
                        </MDBDropdownItem>
                        {/* <MDBDropdownItem> */}
                        {/* <MDBLink
                      className="text-muted text-capitalize p-0"
                      to="/customer/wishlist"
                    >
                      <HeaderMyFavorites />
                    </MDBLink> */}
                        {/* </MDBDropdownItem> */}
                        <MDBDropdownItem divider />
                        <MDBDropdownItem className="text-uppercase" onClick={logOut}>
                          <MDBLink
                              className="text-muted text-uppercase p-0"
                              to="/"
                              onClick={() => setModal(false)}
                          >
                            {/* <HeaderSignOut /> */}
                            Sign Out
                          </MDBLink>
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </>
              ) : (
                  <>
                    <MDBLink
                        to="#"
                        className="p-0 text-uppercase"
                        onClick={() => {
                          toggleModal();
                          setActiveItem("2");
                        }}
                    >
                      <HeaderRegister />
                    </MDBLink>
                    <MDBLink
                        to="#"
                        className="p-0 text-uppercase"
                        onClick={() => {
                          toggleModal();
                          setActiveItem("1");
                        }}
                    >
                      <HeaderLogin />
                    </MDBLink>
                  </>
              )}
            </MDBCol>
            {displaySearch ? (
              <MDBCol>
                <Search
                  open={displaySearch}
                  setOpen={setDisplaySearch}
                  searchVal={searchVal}
                  setSearchVal={setSearchVal}
                />
              </MDBCol>
            ) : (
              <MDBRow>
                <MDBCol className="header-bottom-float-right-container d-flex justify-content-center pr-0">
                  {isLoggedIn ? (
                    <>
                      <Icon
                        icon={"search"}
                        className="search-icon"
                        onClick={() => {
                          setDisplaySearch(!displaySearch);
                        }}
                      />
                      <QuotationList />
                      <WishlistFront />
                    </>
                  ) : (
                    <Icon
                      className="search-icon fas fa-search"
                      icon={"search"}
                      onClick={() => {
                        setDisplaySearch(!displaySearch);
                      }}
                    />
                  )}
                </MDBCol>
              </MDBRow>
            )}
            <MDBHamburgerToggler
              color="#d3531a"
              id="hamburger1"
              onClick={() => {
                toggleMobileMenu();
                toggleHamburger();
                closeBurger();
              }}
            />
          </MDBBox>
        </MDBBox>

      </MDBContainer>
      <ModalWrapper
          toggleModal={toggleModal}
          modal={!isLoggedIn && modal}
          activeItem={activeItem}
          toggleTabs={toggleTabs}
      />
      <GetCart />
      {showMobileMenu && (
        <MDBContainer fluid className="mobile-menu m-0 p-0">
          <MobileNav
            toggleMobileMenu={toggleMobileMenu}
            menuItems={menuItems}
            closeBurger={closeBurger}
          />
        </MDBContainer>
      )}
    </>
  );
};

export default Bottom;

import React, {useEffect} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import globalPages, {
  DefaultPage,
  ErrorPage,
  TemporaryPage,
} from "app/config/routing/pages/globalPages";
import customerPages from "app/config/routing/pages/customerPages";
import guestPages from "app/config/routing/pages/guestPages";
import DynamicRouter from "./dynamic-content-router";
import DynamicGraphQlWrapperQueryContainer from "app/overrides/dynamicGraphQlWrapper";
import queryLoader from "app/config/routing/loader";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import Analytics from "react-router-ga";

const Router = (props) => {
  let globalProps = props;
  const { isLoggedIn } = useCustomer();
    const history = useHistory();
    //
    // useEffect(() => {
    //     const unlisten = history.listen(() => {
    //         window.location.reload();
    //     });
    //     return () => {
    //         unlisten();
    //     };
    // }, [history]);
  if (isLoggedIn) {
    return (
      <Analytics id="">
        <Switch>
          <Route key={"index"} path="/" exact component={DefaultPage} />
          {Object.keys(customerPages).map((key) => {
            let page = customerPages[key];
            let Component = customerPages[key].component;
            return (
              <Route
                key={`${page.url}-${key}`}
                path={"/" + page.url}
                exact={page.exact}
                render={(innerProps) => (
                  <Component {...innerProps} variables={{ url: page.url }} />
                )}
              />
            );
          })}
          {Object.keys(globalPages).map((key) => {
            let Component = globalPages[key].component;

            return (
              <Route
                key={key}
                path={"/" + key}
                exact={globalPages[key].exact}
                render={(innerProps) => (
                  <Component {...innerProps} variables={{ url: key }} />
                )}
              />
            );
          })}
          <Route
            path="/:url"
            render={(props) => {
              return (
                <DynamicGraphQlWrapperQueryContainer
                  query={queryLoader("urlResolver")}
                  queryIdentifier={"urlResolver"}
                  variables={{ url: props.location.pathname.substr(1) }}
                  loader={false}
                  data={globalProps.data}
                  loadingComponent={() => {
                    return (
                      <>
                        <TemporaryPage />
                      </>
                    );
                  }}
                >
                  <DynamicRouter
                    {...globalProps}
                    variables={{ url: props.location.pathname.substr(1) }}
                  />
                </DynamicGraphQlWrapperQueryContainer>
              );
            }}
          />
        </Switch>
      </Analytics>
    );
  } else {
    return (
      <Analytics id="">
        <Switch>
          <Route key={"index"} path="/" exact component={DefaultPage} />
          {Object.keys(guestPages).map((key) => {
            let Component = guestPages[key].component;
              console.log("routeKey ljubica",  key);
            return (
              <Route
                key={key}
                path={"/" + key}
                exact={guestPages[key].exact}
                render={(innerProps) => (
                  <Component {...innerProps} variables={{ url: key }} />
                )}
              />
            );
          })}
          {Object.keys(globalPages).map((key) => {
            let Component = globalPages[key].component;
            return (
              <Route
                key={key}
                path={"/" + key}
                exact={globalPages[key].exact}
                render={(innerProps) => (
                  <Component {...innerProps} variables={{ url: key }} />
                )}
              />
            );
          })}

          <Route
            path="/:url"
            render={(props) => {
              return (
                <DynamicGraphQlWrapperQueryContainer
                  query={queryLoader("urlResolver")}
                  queryIdentifier={"urlResolver"}
                  variables={{ url: props.location.pathname.substr(1) }}
                  loader={false}
                  data={globalProps.data}
                  loadingComponent={() => {
                    return (
                      <>
                        <TemporaryPage />
                      </>
                    );
                  }}
                >
                  <DynamicRouter
                    {...globalProps}
                    variables={{ url: props.location.pathname.substr(1) }}
                  />
                </DynamicGraphQlWrapperQueryContainer>
              );
            }}
          />
          {/* If you want to redirect all guests to specific url if page is not available or found */}
          {/* <Redirect key={"redirection"} to="/" component={DefaultPage} /> */}

          <Route key={"not-found"} component={ErrorPage} />
        </Switch>
      </Analytics>
    );
  }
};

export default Router;

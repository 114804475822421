import React, {useEffect, useState} from "react";
import {
    MDBBox,
    MDBCol,
    MDBContainer,
    MDBDataTable, MDBListGroup, MDBListGroupItem,
    MDBRow, MDBSmoothScroll,
    MDBTable,
    MDBTableBody, MDBTableHead, MDBTooltip,
    MDBTypography, toast,
    ToastContainer
} from "mdbreact";
import SeoHelmet from "../../layout/seoHelmet";
import ProductNameSection from "./product-name-section";
// import Options from "./options-section";
// import OptionsModal from "./options-section/modal";
import QuoteRequestWishlistButton from "./quote-request-button";
import DownloadSection from "./downloadSection";
import Icon from "../../assets/icon/icon";
import OpitonsArkansas from "./options-section/indexArkansas";
// import {useQuery} from "@apollo/react-hooks";
// import {FETCH_PRODUCT} from "../catalog/product/queries";
// import ProductDescriptionMobile from "./productDescription-mobile";
// import ProductDescription from "./productDescription/productDimensions";
// import ParametersSectionMobile from "./parameters-section-mobile";
// import ParametersSection from "./parameters-section";
import {useMediaQuery} from "react-responsive";
import RelatedProducts from "./related-products/relatedProducts-query-container";
import useCustomer from "../../state/hooks/customerHooks/useCustomer";
import {Msg} from "./messages";
import {useWishlist} from "../../state/hooks/wishlistHooks/wishlistHookAddRemove";
import CmsBlock from "app/layout/cmsBlock";


const ProductDetailsPageArkansas = ({ customerGroupId, ...props }) => {

         const {productData} = props;

        const colorPickerRALValue = "";
        const colorPickerValue = "";
         let questions = ["Arkansas"];
        const { isLoggedIn } = useCustomer();
        const [addToWishlist, , , , , , wishlist, , , , , ,] = useWishlist();
        const [activeCatalogNoPossition, setActiveCatalogNoPossition] = useState(0);
        let chosenProduct = Array.isArray(props?.items)  ? props?.items[activeCatalogNoPossition]?.product : props;
        const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
        const [fullscreen, setFullscreen] = useState(false);
        const [activeCatalogNo, setActiveCatalogNo] = useState(chosenProduct.sku);
        const selectedCustomizableOptionId = [
            {
                option_id: chosenProduct.id,
                required: false,
                sort_order: 1,
                title: "selected_options",
                __typename: "CustomizableFieldOption"
            }
        ]



       // const options
        const catalogNoMap = Array.isArray(props?.items)  ? props?.items?.map((a)=> {
            return a;
        }) : props;

        let productToAddToWishlist = {
            products: [{ sku: props.sku }],
            wishlists: [{ name: "My Favorites" }],
        };

        const wishlistItems =
            wishlist?.data?.wishlistItems && wishlist?.data?.wishlistItems[0]?.items;

        const isInWishlist = wishlistItems?.filter(
            (item) => item.product?.sku === props?.sku
        );

        const handleAddToWishlist = () => {
            addToWishlist(productToAddToWishlist);
            toast(<Msg />);
        };

        let skusAndPrices = [
            {
              sku: chosenProduct?.sku,
              price: chosenProduct?.price_range?.minimum_price?.regular_price?.value
            }

        ];

    let selectedOptionsMap =["Arkansas",
        {
            question: "Catalog No.",
            name: chosenProduct?.name,
            generated_sku: skusAndPrices,
            catalogNumber: chosenProduct?.name,
            arkansasSalesDescription: chosenProduct?.ark_custitem_prima_sales_desc
        }];

    const CreateImageLink = (image) => {
        var a = document.createElement("a");
        a.href = image;
        a.rel = "noopener noreferrer";
        a.target = "_blank";
        a.download = chosenProduct?.image?.url;
        a.click();
    };

    const save3DImage = (e) => {

        CreateImageLink(chosenProduct?.image?.url);
    }
    const [specificationEmpty,setspecificationEmpty] = useState(true);

    var noAvailabilityFallback = (availabilityValue, availabilityType) => {
        if (availabilityValue !== 0) return Math.floor(availabilityValue);
        if (chosenProduct.ark_lamp_type === "Pendant" && chosenProduct.ark_made_in_usa !== null) {
            switch (availabilityType) {
                case "1-2":
                    return "Call";
                case "2-4":
                    return "Call";
                case "4-6":
                    return "Unlimited";
                case "6-14":
                    return "Unlimited";
                default:
                    return "Call";
            }
        }
        return Math.floor(availabilityValue);
    }

    useEffect(() => {
        if(!chosenProduct?.ark_main_color &&
            !chosenProduct?.ark_shade_dimensions &&
            !chosenProduct?.ark_material_type &&
            !chosenProduct?.ark_shade_material &&
            !chosenProduct?.ark_primary_finish &&
            !chosenProduct?.ark_power_cord &&
            !chosenProduct?.ark_voltage &&
            !chosenProduct?.ark_bulb_wattage &&
            !chosenProduct?.ark_base &&
            !chosenProduct?.ark_custitem_ul_rating
        ) {
            setspecificationEmpty(false)
        }
    }, []);


    return(
        <MDBContainer
            style={{scrollBehavior: "smooth"}}
            fluid
            className="text-center text-md-left typography-wrapper w-100 ml-0 mr-0 pl-0 pr-0"
            id="product-page-container"
        >
            <SeoHelmet />
            <ToastContainer autoClose={ 300 } hideProgressBar />
            <MDBRow className="breadcrumbs-row w-100 no-gutters" >
                <MDBCol size="12" lg="12">
                    <ProductNameSection
                        categories={props?.categories}
                        name={props?.name}
                        // catalogNoMap={catalogNoMap}
                    />
                </MDBCol>
                <MDBCol  size="12" className="product-name-and-3D-icon">
                    <MDBTypography tag="h1" className="h2 mb-2">
                        {props?.name}
                    </MDBTypography>
                </MDBCol>
            </MDBRow>
            <MDBRow className="no-gutters main-row-pdp ml-0 mr-0 pl-0 pr-0 w-100">
                <MDBCol
                    size="12"
                    lg="4"
                    md="12"
                    sm="12"
                    className="top-left-section p-0 ml-0 mr-0 pl-0 pr-0"
                >
                    <MDBRow className="sku-price-wrapper no-gutters ml-0 mr-0 pl-0 w-100 pr-0 pr-0 mt-5 pt-5">
                        <span className="catalog text-bold">VARIATIONS: </span>
                    </MDBRow>
                    <OpitonsArkansas
                        catalogNoMap={catalogNoMap}
                        activeCatalogNo={activeCatalogNo}
                        setActiveCatalogNo={setActiveCatalogNo}
                        activeCatalogNoPossition={activeCatalogNoPossition}
                        setActiveCatalogNoPossition={setActiveCatalogNoPossition}
                        // catalogNumbers={}
                    />


                    {/*Item Number*/}
                    <MDBRow className="sku-price-wrapper no-gutters ml-0 mr-0 pl-0 pb-3  w-100 pr-0"  style={{ borderBottom: "1px solid #e4e4e7" }}>
                       <span style={{marginBottom:"15px", marginTop:"15px"}} className="catalog text-bold">ITEM NUMBER: </span><span style={{marginBottom:"15px", marginTop:"15px"}}  className="catalog ml-3"> {activeCatalogNo}</span>
                    </MDBRow>
                    {/*Description*/}
                    <MDBRow className="sku-price-wrapper no-gutters ml-0 mr-0 pl-0 pb-3  w-100 pr-0">
                        <span className="pb-3  w-100 catalog text-bold" style={{marginTop:"15px"}}>DESCRIPTION: </span>
                        <span className="catalog">{chosenProduct?.ark_custitem_prima_sales_desc}</span>
                    </MDBRow>
                    {/*<MDBRow className="sku-price-wrapper no-gutters ml-0 mr-0 pl-0 pb-3 mb-3 w-100 pr-0"  style={{ borderBottom: "1px solid #e4e4e7" }}>*/}
                    {/*    <a href={"#identitySpefificationSeciton"} style={{marginBottom:"15px", textDecoration:"underline"}} className="catalog text-bold " onClick={(e) => {*/}
                    {/*        e.preventDefault();*/}


                    {/*        document.querySelector(e.target.getAttribute('href')).scrollIntoView({*/}
                    {/*            behavior: 'smooth',*/}
                    {/*            block: "center"*/}
                    {/*        });*/}
                    {/*    }} > SPECIFICATIONS <i href={"#identitySpefificationSeciton"} style={{fontSize: "13px"}} className="fas fa-arrow-down"></i></a>*/}
                    {/*    /!*SPECIFICATIONS <i style={{fontSize: "13px"}} className="fas fa-arrow-down"></i>*!/*/}
                    {/*</MDBRow>*/}
                    <MDBRow className="availability-table-ark-sku-price-wrapper sku-price-wrapper no-gutters ml-0 mr-0 pl-0 pb-4 pt-3 w-100 pr-0">
                        <MDBTable  className="availability-table-ark" responsive  fixed >
                            <MDBTableHead>
                                <tr>
                                    <th className="text-bold" style={{background:"#f2f2ed"}} ><b>In Stock Today</b></th>
                                    <th className="text-bold"><b>Total Available 1-2 Weeks</b></th>
                                    <th className="text-bold"><b>Total Available 2-4 Weeks</b></th>
                                    <th className="text-bold"><b>Total Available 4-6 Weeks</b></th>
                                    <th className="text-bold"><b>Total Available 6-14 Weeks</b></th>
                                    <th className="text-bold"><b>Available 14+ Weeks</b></th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                <tr>
                                    <th style={{background:"#f2f2ed"}}>{Math.floor(chosenProduct?.ark_quantityavailable)}</th>
                                    <th>{noAvailabilityFallback(chosenProduct?.ark_web_available_1, "1-2")}</th>
                                    <th>{noAvailabilityFallback(chosenProduct?.ark_web_available_2, "2-4")}</th>
                                    <th>{noAvailabilityFallback(chosenProduct?.ark_web_available_4, "4-6")}</th>
                                    <th>{noAvailabilityFallback(chosenProduct?.ark_web_available_6, "6-14")}</th>
                                    {/*<th>{chosenProduct?.ark_web_available_14}</th>*/}
                                    <th>Unlimited - Special Order</th>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                        <span className="catalog" >Stock Status is based on previous days inventory level</span>
                    </MDBRow>
                    {chosenProduct.ark_turnaround_code === "S" ? (

                            <MDBRow className="sku-price-wrapper no-gutters ml-0 mr-0 pl-0 pb-3  w-100 pr-0">
                                <span className="catalog text-bold">SHIPPING CODE: </span><span className="catalog ml-3 mr-1" > SPECIAL ORDER </span>
                                <MDBTooltip  placement={"right"} domElement tag="span" clickable={true} isVisible={true}  >
                                    <div>
                                        <Icon role={`top`} icon={`info`} />
                                    </div>
                                    <div>
                                        <span>
                                            {/*<CmsBlock id={"tooltip_special_code"} />*/}
                                            Special Order, lead times are 90-120 days with ocean freight included.
                                        </span>
                                    </div>
                                </MDBTooltip>
                            </MDBRow>



                    ) : <></>
                    }

                    <MDBRow className="sku-price-wrapper no-gutters ml-0 mr-0 pl-0 pb-4 mb-4  w-100 pr-0"  style={{ borderBottom: "1px solid #e4e4e7" }}>
                        <span className="catalog text-bold">MINIMUM ORDER QTY: </span><span className="catalog  ml-3"> {chosenProduct?.ark_min_order_qty}</span>
                    </MDBRow>
                    <MDBRow className="sku-price-wrapper no-gutters ml-0 mr-0 pl-0 pb-3  w-100 pr-0" >
                        <MDBCol style={{paddingTop:"15px", paddingBottom: "15px" }} ><span  className="catalog text-bold">ITEM NUMBER: </span><span  className="catalog"> {activeCatalogNo}</span></MDBCol>


                        <MDBCol >{isLoggedIn && customerGroupId === 4 ? (
                            <>
                            {chosenProduct.ark_closeout_price !== null && chosenProduct.ark_closeout && chosenProduct.ark_closeout === 1 && (
                                <>
                                    <p style={{textAlign:"right", fontSize:"1.9rem"}}><span style={{textAlign:"right", fontSize:"1.4rem", textDecorationLine: "line-through"}}>${chosenProduct?.price_range?.minimum_price?.regular_price?.value}</span> ${ parseFloat(chosenProduct.ark_closeout_price).toFixed(2) }</p>

                                </>

                            )}
                            {Object.keys(chosenProduct?.price_tiers).length === 0  &&  chosenProduct.ark_closeout_price === null && !chosenProduct.ark_closeout && chosenProduct.ark_closeout === 0 && (
                                <p style={{textAlign:"right"}}>${chosenProduct?.price_range?.minimum_price?.regular_price?.value.toFixed(2)}</p>
                            )}
                            </>

                        ) : <></>}</MDBCol>
                        {isLoggedIn && customerGroupId === 4 && chosenProduct.ark_closeout_price === null && !chosenProduct.ark_closeout && chosenProduct.ark_closeout === 0  && chosenProduct?.price_tiers.length !== 0 &&(
                            <>
                                <MDBTable  className="availability-table-ark" responsive  fixed >
                                    <MDBTableHead>

                                        <tr>
                                            {chosenProduct?.price_tiers && chosenProduct?.price_tiers?.map((tiers) => {
                                                return <th className="text-bold" style={{fontSize:"1.2rem"}} ><b>QTY {tiers?.quantity}+</b></th>
                                            })}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        <tr>
                                            {chosenProduct?.price_tiers && chosenProduct?.price_tiers?.map((tiersP) => {
                                                return <th style={{fontSize:"1.2rem"}}  >${tiersP?.final_price?.value.toFixed(2)}</th>
                                            })}
                                        </tr>
                                    </MDBTableBody>
                                </MDBTable>
                                <span className="catalog" style={{fontSize: "1.1rem", fontWeight:"600"}}>*Freight allowance not included. Please ask for a freight quote. </span>
                            </>

                        )}

                    </MDBRow>

                    <QuoteRequestWishlistButton
                        arkansasDescrption={chosenProduct?.ark_custitem_prima_sales_desc ? chosenProduct?.ark_custitem_prima_sales_desc : false}
                        selectedOptionsMap={selectedOptionsMap}
                        questions={questions}
                        isLoggedIn={isLoggedIn}
                        handleAddToWishlist={handleAddToWishlist}
                        isInWishlist={isInWishlist}
                        customerGroupId={customerGroupId}
                        sku={chosenProduct.sku}
                        catalogNumbers={chosenProduct.sku}
                         selectedCustomizableOptionId={selectedCustomizableOptionId}
                         genratedSku={skusAndPrices}
                         catalogNumber={chosenProduct.sku}
                        // lengthOfQuestions={lengthOfQuestions}
                        // lengthOfSelectedOptions={lengthOfSelectedOptions}
                         skusAndPrices={skusAndPrices}
                        // getCanopyPrice={props.getCanopyPrice}
                        colorPickerValue={colorPickerValue}
                        // colorPickerRALValue={colorPickerRALValue}
                    />
                    <div style={{borderBottom: "1px solid #1F1E3F", marginTop: "2rem", marginBottom: "1rem"}}></div>

                    <DownloadSection
                        activeCatalogNo={chosenProduct?.sku}
                        selectedOptionsMap={selectedOptionsMap}
                        skusAndPrices={skusAndPrices}
                        data={chosenProduct}
                        save3DImage={save3DImage}
                    />
                </MDBCol>
                {/*HERE STARTS MAIN IMAGE DISPLAY*/}
                <MDBCol
                    size="12"
                    lg="7"
                    md="12"
                    sm="12"
                    className="main-image custom-shadow top-middle-section ml-0 mr-0 pl-md-4 pr-md-4"
                >

                    {chosenProduct.ark_closeout && chosenProduct.ark_closeout === 1 ? (
                        <div className={"closeout-item"}>Clearance</div>
                    ) : null}
                        <img
                            onClick={() => {
                                setFullscreen(!fullscreen);
                            }}
                            className={`product-image ${
                                fullscreen ? "fullscreen-canvas" : ""
                            }`}
                            src={
                                chosenProduct?.image?.url
                            }
                            alt={chosenProduct?.image?.label}
                        />
                        {fullscreen ? (
                            <MDBBox
                                className="close-fullscreen-img"
                                onClick={() => {
                                    setFullscreen(!fullscreen);
                                }}
                            >
                                <Icon icon={"minimize"} width="30px" height="30px" />
                            </MDBBox>
                        ) : (
                            <MDBBox
                                className="enlarge-pdp"
                                onClick={() => {
                                    setFullscreen(!fullscreen);
                                }}
                            >
                                <Icon icon={"enlarge"} />
                            </MDBBox>
                        )}
                </MDBCol>
                {/*SPACE WRAPPER FOR GALERY PRODUCT IMAGES START*/}
                <MDBCol
                    size="12"
                    lg="1"
                    md="12"
                    sm="12"
                    className="top-right-section"
                ></MDBCol>
                {/*SPACE WRAPPER FOR GALERY PRODUCT IMAGES START*/}
            </MDBRow>
            {/*<MDBRow containerRef={"identitySpefificationSeciton"} id="identitySpefificationSeciton"></MDBRow>*/}
            {specificationEmpty && (
                <div className="description-wrapper  description-wrapper-arkansas px-md-5 px-sm-3"  >
                    <MDBRow className="data-row no-gutters" id="identitySpefificationSeciton">
                        <MDBCol md="12" lg="12" className="m-0 parameters-section-arkansas p-0 w-100">
                            <h3 style={{color:"#211F44"}}  >Specification</h3>
                        </MDBCol>

                        <MDBCol className="description-and-size-wrapper" md="12" lg="6">
                                <MDBRow className="no-gutters mt-5 ">
                                    <MDBCol md="12" sm="12">
                                        <MDBRow className="no-gutters parameters-section-wrapper" style={{textAlign:"left"}}>
                                            {chosenProduct?.ark_main_color  && (
                                                <>
                                                    <MDBCol md="6"  sm="6" xs="6"><p ><b>Main Color</b></p></MDBCol><MDBCol md="6" sm="6" xs="6"><p >{chosenProduct?.ark_main_color}</p></MDBCol>
                                                </>
                                            )}
                                            {chosenProduct?.ark_shade_dimensions  && (
                                                <>
                                                    <MDBCol md="6" sm="6" xs="6"><p><b>Shade Dimension</b></p></MDBCol><MDBCol md="6" sm="6" xs="6"><p >{chosenProduct?.ark_shade_dimensions}</p></MDBCol>
                                                </>
                                            )}
                                            {chosenProduct?.ark_material_type  && (
                                                <>
                                                    <MDBCol md="6" sm="6"  xs="6"><p ><b>Material Type</b></p></MDBCol><MDBCol md="6" sm="6" xs="6"><p >{chosenProduct?.ark_material_type}</p></MDBCol>
                                                </>
                                            )}
                                            {chosenProduct?.ark_shade_material  && (
                                                <>
                                                    <MDBCol md="6" sm="6"  xs="6"><p ><b>Shade Material</b></p></MDBCol><MDBCol md="6" sm="6" xs="6"><p >{chosenProduct?.ark_shade_material}</p></MDBCol>
                                                </>
                                            )}
                                            {chosenProduct?.ark_primary_finish  && (
                                                <>
                                                    <MDBCol md="6" sm="6"  xs="6"><p ><b>Finish</b></p></MDBCol><MDBCol md="6" sm="6" xs="6"><p >{chosenProduct?.ark_primary_finish}</p></MDBCol>
                                                </>
                                            )}
                                            {chosenProduct?.ark_custitem_ul_rating  && (
                                                <>
                                                    <MDBCol md="6" sm="6" xs="6"><p><b>UL Rating</b></p></MDBCol><MDBCol md="6" sm="6" xs="6"><p >{chosenProduct?.ark_custitem_ul_rating}</p></MDBCol>
                                                </>
                                            )}
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>

                        </MDBCol>
                        <MDBCol className="description-and-size-wrapper " md="12" lg="6">

                                <MDBRow className="no-gutters mt-5" >
                                    <MDBCol md="12" sm="12" className="">
                                        <MDBRow className="no-gutters parameters-section-wrapper " style={{textAlign:"left"}}>
                                            {chosenProduct?.ark_power_cord  && (
                                                <>
                                                    <MDBCol md="6" sm="6" xs="6"><p><b>Power cord</b></p></MDBCol><MDBCol md="6" sm="6" xs="6"><p >{chosenProduct?.ark_power_cord}</p></MDBCol>
                                                </>
                                            )}
                                            {chosenProduct?.ark_voltage  && (
                                                <>
                                                    <MDBCol md="6" sm="6" xs="6"><p><b>Voltage</b></p></MDBCol><MDBCol md="6" sm="6" xs="6"><p >{chosenProduct?.ark_voltage}</p></MDBCol>
                                                </>
                                            )}
                                            {chosenProduct?.ark_bulb_wattage  && (
                                                <>
                                                    <MDBCol md="6" sm="6" xs="6"><p><b>Bulb wattage</b></p></MDBCol><MDBCol md="6" sm="6" xs="6"><p >{chosenProduct?.ark_bulb_wattage}</p></MDBCol>
                                                </>
                                            )}
                                            {chosenProduct?.ark_base  && (
                                                <>
                                                    <MDBCol md="6" sm="6" xs="6"><p><b>Base</b></p></MDBCol><MDBCol md="6" sm="6" xs="6"><p >{chosenProduct?.ark_base}</p></MDBCol>
                                                </>
                                            )}

                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>

                        </MDBCol>
                    </MDBRow>
                </div>
            )}
            {props.categories ? (
                <RelatedProducts currentCategory={props.categories} />
            ) : (
                <></>
            )}

        </MDBContainer>
    )
}

export default ProductDetailsPageArkansas;
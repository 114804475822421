import React, { useState } from "react";
import { useCart } from "app/state/hooks/cartHooks/useCart";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  Typography,
  MDBBox,
  MDBTypography,
} from "mdbreact";
import ContactInformation from "./contactInformation";
import QuoteRequestItem from "./item";
import { Redirect } from "react-router-dom";
import { unescape } from "lodash";
import Icon from "app/assets/icon/icon";
import { useReactToPrint } from "react-to-print";
import { QuotePdf } from "./quote-pdf";
import Logo from "../logo";
import PrimaLogo from "../../assets/images/logo-black.png";
const QuoteRequest = (props) => {
  let { headers } = props;
  const { getCart } = useCart();
  const cart = getCart();
  let cartId = cart?.id;
  const [message, setMessage] = useState("");
  const [currentSkuAndComments, setCurrentSkuAndComments] = useState();
  const pdfComponent = React.useRef();
  const savePdf = useReactToPrint({
    content: () => pdfComponent.current,
  });
  const [colorPickerValuseQR, setColorPickerValueQR] = useState("");
  let skuAndComments = [];

  if (typeof cart === "undefined") {
    return <Redirect to="/" />;
  }
  cart &&
    cart.items.map((item) => {
     // console.log("cartItem",item);
      skuAndComments.push({
        product_sku: item.product.sku,
        comment: "",
        remove_line: 1,
        custom_price: "",
      });
    });

  let cartItems = cart.items.map((item) => {
    const selectedOptions = item?.simple_customizable[0];
    const enteredOptions = selectedOptions?.values[0].value;

    let removesQuotes = unescape(enteredOptions)
      .replace('"[', "[")
      .replace(']"', "]");
    const parsedOptions =
      selectedOptions !== undefined ? JSON.parse(removesQuotes) : [];
    return { ...item, parsedOptions };
  });

  let getUuid = (item) => {
    let myUuid = item.parsedOptions.find((q) => {
      return !!q.uuid;
    })?.uuid;
    return myUuid;
  };

  cartItems = cartItems.map((item) => {
    let itemUuid = getUuid(item);
    let pair = cartItems.find((i) => {
      return itemUuid === getUuid(i) && item.id !== i.id;
    });
    let pairQty = 0;

    if (pair) {
      pairQty = pair.quantity;
    }
    return { ...item, pairQty, pairId: pair?.id };
  });
  const pdfProps = {
    items: cartItems,
    colorPicker: colorPickerValuseQR,
    currentSkuAndComments: currentSkuAndComments,
  };

  return (
    <MDBContainer fluid className="main-container quote-request-wrapper">
      <MDBRow>
        <MDBCol xl="7" md="12" className="left-column">
          <Typography tag="h1">Quote Request</Typography>
          <MDBRow className="main-row">
            <MDBCol size="12">
              {cartItems.map((item, i) => {
                let itemQuantity = props.itemQuantities.find(
                  (it) => it.id === item.id
                );
                let itemQuantityValue =
                  typeof itemQuantity !== "undefined"
                    ? itemQuantity.quantity
                    : 0;

                const isPairNext =
                  cartItems[i + 1]?.id === item.pairId || false;
                return (
                  <QuoteRequestItem
                    i={i}
                    item={item}
                    key={`quote-request-item-${item.id}`}
                    cartId={cartId}
                    sku={item.product.sku}
                    quantity={itemQuantityValue}
                    removeItem={() => props.removeItem(item.id)}
                    setQuantity={props.setItems(item.id)}
                    increaseQuantity={() => props.increaseItems(item.id)}
                    decreaseQuantity={() => props.decreaseItems(item.id)}
                    headers={headers}
                    setLoading={props.setLoading}
                    skuAndComments={skuAndComments}
                    loading={props.loading}
                    parsedOptions={item.parsedOptions}
                    currentSkuAndComments={currentSkuAndComments}
                    setCurrentSkuAndComments={setCurrentSkuAndComments}
                    pairQty={item.pairQty}
                    isConnected={isPairNext}
                    hasTopBorder={!i}
                    setColorPickerValueQR={setColorPickerValueQR}
                  />
                );
              })}
            </MDBCol>
          </MDBRow>
          <MDBRow className="border-top py-5">
            <MDBCol
              onClick={savePdf}
              className="d-flex align-items-center justify-content-end cursor-pointer pr-md-0"
            >
              <Icon
                icon="printpdf"
                style={{ height: "100%" }}
                className="mr-3"
              />
              <MDBTypography
                tag="p"
                className="text-uppercase mb-0"
                style={{ fontWeight: 500, fontSize: "1.4rem" }}
              >
                Save quote request as pdf
              </MDBTypography>
            </MDBCol>
          </MDBRow>

        </MDBCol>
        <MDBCol xl="5" md="12" className="right-column">
          <ContactInformation
            currentSkuAndComments={currentSkuAndComments}
            cartId={cartId}
            {...props}
            message={message}
            setMessage={setMessage}
          />
        </MDBCol>
      </MDBRow>
      <MDBBox className="d-none">
        <QuotePdf ref={pdfComponent} {...pdfProps}  />
      </MDBBox>
    </MDBContainer>
  );
};

export default QuoteRequest;

import ProductDisplay from "./product-details-page";
import React, { useContext } from "react";
import { parse } from "app/state/hooks/VereniaParser";
import { VereniaContext } from "app/state/context/verenia";

const ProductDetailsPage = React.memo((props) => {
  const vereniaContext = useContext(VereniaContext);
  const vereniaImportData = vereniaContext?.data;

  const data =
    typeof vereniaImportData === "undefined" ? false : vereniaImportData;
  const [vars, setVars] = React.useState({
    PROD: props.PROD,
    PRODLINE: props.PRODLINE,
  });
  const catalognumbers = props.catalog_numbers;
  let firstCatasplitString = catalognumbers.split(',');

  const [skuState, setSkuState] = React.useState({
    PROD: props.PROD,
    PRODLINE: props.PRODLINE,
    CATA: firstCatasplitString[0]
  });



  let skuInitialState = {PROD: props.PROD, PRODLINE: props.PRODLINE}

  let pricing = {};
  const [player, setPlayer] = React.useState(null);

  const setVar = (variable, value) => {
    setVars({ ...vars, [variable]: value.replace(/-/g, "_") });
  };

  let questions = [],
    all_questions = [];
  if (data) {
    all_questions = data;
  }

  all_questions.forEach((aq) => {
    if (aq.name !== "Product" && aq.name !== "Product Line") {

      let debug = false;
      if (aq.name === "Lumen Style") {
        debug = true;

      }



      if (!parse(aq.rule, skuState, debug)) {
        questions.push(aq);
      }

      if(!aq.code in skuState) {
          questions.push(aq);
      }
    }
  });
  questions.forEach((q) => {

    let newAnsers = q.answers.filter((a) => {
      let thisProductPrices = a.product_price.filter((pp) => {
        if (pp.name.toLowerCase() === props.name.toLowerCase()) return true;
        return false;
      });

      let price = Number(a.price);
      if (!price && thisProductPrices.length > 0) {
        price = thisProductPrices[0].price;
      }

      if (price) {
        pricing[a.sku] = { price };
      }

      return !parse(a.rule, skuState);
    });

    newAnsers.sort((c, n) => {
      // current, next
      return c.seq - n.seq;
    });

    q.parsedAnswers = newAnsers;

    return q;
  });

  const getCanopyPrice = (sku) => {
    return pricing?.[sku.replace(/-/g, "_")]?.price || false;
  };

  const questionsUnsorted = [...questions];
  questions.sort((a, b) => {
    if (Number(a.seq) > Number(b.seq)) {
      return 1;
    }
    return -1;
  });

  // hide suspension code for products from ceiling sconces category
  let isCeilingSconces =
    typeof props.categories !== "undefined" && props.categories !== null
      ? props.categories.find((x) =>
          x.name.toLowerCase().includes("ceiling sconces")
        )
      : false;



  /*
   Passes generated price to the
   @ f displayProps which passes variables to the component ProductDisplay
   @ f generateSku
   */
  let generatedPrice = generatePrice(skuState, pricing, props.skuExceptions);


  let allSkus = genrateSku(
    skuState,  // sku state need to render skus insted of vars
    questions,
    props.skuExceptions,
    generatedPrice,
    isCeilingSconces,

  );


  let genratedSku = allSkus;

  let displayProps = {
    all_questions,
    questions,
    questionsUnsorted,
    setVar,
    setVars,
    genratedSku,
    price: generatedPrice,
    vars: skuState,
    allSkus,
    player,
    setPlayer,
    getCanopyPrice,
    setSkuState,
    skuState,
    skuInitialState
  };

  return <ProductDisplay {...props} {...displayProps} />;
});

/*
* GENERATED PRICE DEFINE PRICE FOR ALL ITEMS THAT DO NOT BELONG TO jola_skuexception_skuexception:
*  Data is definded by:
*  @skystate: array of selected options
*  @pircing: array of mapped prices per sku
*  @se: array form Sku_exceptions (@info: database records used for breakout items escaped in generated prices)
*
*  sku that do not belong to @se will not belong to breakout item BUT can have code: LAMP
*  that we can se by joining:
*  AS
* */
const generatePrice = (vars, pricing, se) => {
  let isAdded = [];
  let price = 0;
  const separatableSkus = ["LAMP", "DRIV", "ESP", "BREAKER", "S_WIRE"];
  for (const [a, value] of Object.entries(vars)) {


    if (pricing[value] && vars[a] && a !== "PROD" && a !== "PRODLINE" ) {

      let added = false;

      if (separatableSkus.includes(a)) {
        se.forEach((s) => {

          if (parse(s.rule, vars) && s.rule.includes(a)) {
            added = true;
          }
        });
      }
      if (!added ) {
        isAdded.push(value);
        price += pricing[value].price ;
      }

    }
  }

  return price;
};

/*
Use from @se(array of breakout items) sku and price. In const eta adding the result in array of breakout items in @otherSkus
 */
const genrateSku = (vars, questions, se, generatedPrice, isCeilingSconces) => { //




  let shouldSee = ["MULTI_CS", "BREAKER"];
  questions.forEach((q) => {
    if (q.parsedAnswers.length > 0) {
      let valueOfThisCode = vars[q.code];


      if (typeof vars[valueOfThisCode] === "undefined") {
        shouldSee.push(q.code);
      }
    }
    return q;
  });

  const inSku = {
    CATA: null,
    SYS: null,
    GLASS_COLOR: null,
    TRIM: null,
    TCS: "SYS",
    MONO_CS: "SYS",
    MULTI_CS: null,
    TCSC: "TCS,SYS",
    // M1: "MULTI_CS,SYS",
    // M2: "MULTI_CS,SYS",
    // M3: "MULTI_CS,SYS",
    // M4: "MULTI_CS,SYS",
    // M5: "MULTI_CS,SYS",
    // M6: "MULTI_CS,SYS",
    // M7: "MULTI_CS,SYS",
    LED_OPT: null,
    ID_RD: null,
    LUM: null,
    H_FIN: null,
    SUSP: null,
    IV_DT: "TCS,SYS/MONO_CS,SYS",
    LAMP: null,
    OPT_ANGL: null,
    DRIV: "MONO_CS,SYS",
    ESP: null,
    BREAKER: null,
    WATTAGE: null,
    CURRENT: null,
    HOUSING: null,
    INPUT_VOLTAGE: null,
    OUTPUT: null,
    S_WIRE: null,
    CLR_LENS: null,
  };

  if (isCeilingSconces) {
    delete inSku.SUSP;
  }

  const excludes = [
    "NB",
    "RD",
    "NL",
    "NRD",
    "MONO-CS",
    "MULTI-CS",
    "TCS",
    "NSP",
    "ST",
    "WO-HOUSING",
    "STD",
    "X",
  ];
  const separatableSkus = ["LAMP", "DRIV", "ESP", "BREAKER", "S_WIRE"];
  let skuParts = [];
  let otherSkus = [];
  Object.keys(inSku).map((code) => {
    let added = false;

    if (typeof vars[code] !== "undefined") { //should be just for collecting breakout items
      if (shouldSee.includes(code)) {
        if (separatableSkus.includes(code)) {
          let name = "";
          switch (code) {
            case "LAMP":
              name = "Lamp";
              break;
            case "DRIV":
              name = "Driver";
              break;
            case "BREAKER":
              name = "Breaker";
              break;
            case "ESP":
              name = "ESP";
              break;
            case "S_WIRE":
              name = "Secondary Wire";
              break;
            default:
              break;
          }
          se.forEach((s) => { // se = all breakout items

            if (s.rule.includes(vars[code]) && parse(s.rule, vars)) { //parse(s.rule, vars) replaced with search of substring without regexpresion
              let shouldAdd = true;

              const eta = {
                [name]: vars[code].replace(/_/g, "-"),
                price: s.price, // build prices for separable skus
              };


              otherSkus.forEach((oS) => {
                let oSk = Object.keys(oS);

                if (oSk.includes(name)) {
                  shouldAdd = false;
                }
              });
              if (shouldAdd) {
                added = true;
                otherSkus.push(eta);
              }
            }
          });
        }

        if (!added) {
          let newCode = vars[code].replace(/_/g, "-");
          if (
            !excludes.includes(newCode) ||
            (code === "TRIM" && newCode === "RD")
          ) {
            skuParts.push(newCode);
          }
        }
      }
    }

    return code;
  });
   // this should be logic for price deduction - not used
  let priceDeduction = 0;
  otherSkus.forEach((os) => {
    if (!os.hasOwnProperty("Driver")) {
      priceDeduction += os.price * 2;
    }
  });  // let priceDeduction = 0;

  if(skuParts)
  return [{ sku: skuParts.join("-"), price: generatedPrice }, ...otherSkus];
};

export default ProductDetailsPage;

import React, { useState, useContext } from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { useCart } from "app/state/hooks/cartHooks/useCart";
import useLocking from "core/state/hooks/commonHooks/useLocking";
import ModalWrapper from "app/layout/login/modalWrapper";
import Button from "core/components/button";
import products from "generation/products.json";
import { MiniCartContext } from "app/state/hooks/cartHooks/MinicartContext";
import themeConfiguration from "config/themeConfiguration";
import ReactGa from "react-ga4";




const TRACKING_GTM = "GTM-TG5BVHB"
const TRACKING_ID_live = "G-VWFVG3SK64"; // OUR_TRACKING_ID
const TRACKING_ID_dev = "G-GRXER2X3BQ"; // OUR_TRACKING_ID
try {
  if(themeConfiguration.magento_url === "https://primalightingm2.joladev3.com/") {
    ReactGa.initialize(TRACKING_ID_dev);
  }

  if(themeConfiguration.magento_url === "https://magento.primalighting.com/") {

    ReactGa.initialize(TRACKING_GTM)
    ReactGa.initialize(TRACKING_ID_live);
  }

} catch (e) {
  console.log(e)
}

const QuoteRequestWishlistButton = ({
  handleAddToWishlist,
  isInWishlist,
  isLoggedIn,
  sku,
  selectedOptionsMap,
  questions,
  selectedCustomizableOptionId,
  genratedSku,
  catalogNumber,
  customerGroupId,
  lengthOfQuestions,
  lengthOfSelectedOptions,
  skusAndPrices,
  getCanopyPrice, colorPickerValue,colorPickerRALValue,arkansasDescrption
}) => {

  /*
  google analitics
   */
  const eventTrackAddToQuote = (product_data) => {

    ReactGa.gtag("event", "add_to_quote", {
      sku: product_data[0]?.generated_sku,
      lamp: product_data[6]?.name ? product_data[6]?.name : product_data[2]?.name,
      catalogNumber: product_data[0]?.catalogNumber,
    });
  }

  const miniCartContext = useContext(MiniCartContext);

  const { toggleMiniCart } = miniCartContext;

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [activeItem, setActiveItem] = useState("1");
  const toggleTabs = (tab) => {
    if (activeItem !== tab) {
      setActiveItem(tab);
    }
  };
  const { addProductToCart } = useCart();

  const [lock] = useLocking();
  const [, setLoading] = useState(false);


  var options=[];
  if(selectedOptionsMap[0] === "Arkansas" && questions[0] === "Arkansas"){


    options = [{
      question: selectedOptionsMap[1]?.question,
      name: selectedOptionsMap[1]?.name,
      generated_sku: selectedOptionsMap[1]?.generated_sku,
      catalogNumber: selectedOptionsMap[1]?.catalogNumber,
      arkansasSalesDescription: selectedOptionsMap[1]?.ark_custitem_prima_sales_desc
    }]
  }else {
    options = Object.keys(selectedOptionsMap)
        .map((sek) => {
          let se = selectedOptionsMap[sek];
          let question = questions.filter((q) => {
            return q.code === sek;
          })[0];
          if (question === undefined) return null;

          let questionName = question.name;

          return {
            question: questionName,
            icon: se.iconUrl,
            name: se.name,
            generated_sku: genratedSku,
            catalogNumber: catalogNumber,
          };
        })
        .filter((x) => x !== null);
  }


  let customizable_option_id;
  if (typeof selectedCustomizableOptionId === "undefined") {
    customizable_option_id = 2;
   // console.log("in if")
  } else {
    customizable_option_id = selectedCustomizableOptionId[0]?.option_id;
    //console.log("in elese");
  }

  const getCanopyOptionId = (canopyType) => {
    const canopy = Object.keys(products.default).filter((k) => {
      let a = products.default[k];
      return a.sku === canopyType;
    });
    const optionId = products.default[canopy]?.options?.[0]?.option_id;
    return optionId;
  };


  const addItems = (sku, customizable_option_id, options) => {


    const uuid = Math.random();
    options.push({ uuid: uuid });

    options.push({colorPicker: colorPickerValue });

    let cPrice = null;

    if (customerGroupId === 4) {
      options.push({ prices: skusAndPrices });
    }

    let stringifiedOptions = '"' + JSON.stringify(options) + '"';

    const fixtureQty = parseInt(selectedOptionsMap?.["MULTI_CS"]?.sku[1]) || 1;

    const fixtureData = {
      data: {
        sku: sku,
        quantity: fixtureQty,
      },
      customizable_options: [
        {
          id: customizable_option_id,
          value_string: stringifiedOptions,
        },
      ],
    };
    if (
      selectedOptionsMap?.["SYS"]?.sku === "MULTI_CS" &&
      selectedOptionsMap?.["MULTI_CS"]?.sku &&
      selectedOptionsMap?.[selectedOptionsMap["MULTI_CS"].sku].sku
    ) {
      let canopyType = selectedOptionsMap?.[
        selectedOptionsMap["MULTI_CS"].sku
      ].sku.replace(/_/g, "-");
      if (
        selectedOptionsMap?.[selectedOptionsMap["MULTI_CS"].sku].sku.includes(
          "CO"
        )
      ) {
        canopyType = selectedOptionsMap?.["MULTI_CS"]?.sku;
      }


      const optionId = getCanopyOptionId(canopyType);
      if (customerGroupId === 4) {
        cPrice = getCanopyPrice(canopyType);
      }
      const canopyOptions =
        '"' +
        JSON.stringify([
          {
            question: "Fixtures Selected",
            name: fixtureQty,
          },
          { question: "Fixture", name: sku },
          { uuid: uuid },
          {
            prices: [
              {
                sku: canopyType,
                price: cPrice,
              },
            ],
          },
        ]) +
        '"';

      const canopyData = {
        data: {
          sku: canopyType,
          quantity: 1,
        },
        customizable_options: [
          {
            id: optionId,
            value_string: canopyOptions,
          },
        ],
      };
      // console.log("canopyData", canopyData);

      addProductToCart({
        type: "SimpleProduct",
        data: [canopyData, fixtureData],
      });
    } else {
      // console.log("===========================>fixtureData sku",sku)
      // console.log("===========================>fixtureData fixtureQty", fixtureQty)
      // console.log("===========================>fixtureData customizable_option_id", customizable_option_id)
      // console.log("===========================>fixtureData stringifiedOptions", stringifiedOptions)
      // console.log("fixtureData", fixtureData);
      addProductToCart({
        type: "SimpleProduct",
        data: [fixtureData],
      });
    }
  };
  //console.log("options", options)
  const [buttonText, setButtonText] = useState("Add to quote request");
  // console.log("customizable_option_id is set", customizable_option_id);
  // console.log("options is set", options);
  return (
    <MDBRow className="add-to-quote-request no-gutters">
      {isLoggedIn ? (
        <MDBCol size="12">
          <MDBBtn
            disabled={lengthOfSelectedOptions !== lengthOfQuestions}
            size="lg"
            className="large-button"
            onClick={(e) => {
              setLoading(true);
              setButtonText("Adding...");
              lock({
                always: (e) => {},
                fail: (e) => {
                  setLoading(false);
                },
                success: (e) => {
                  setButtonText("Added");
                  setTimeout(() => {
                    setButtonText("Add to quote request");
                  }, 3000);
                  toggleMiniCart();
                  setLoading(false);
                },
              });
              addItems(sku, customizable_option_id, options);
              eventTrackAddToQuote(options); //ljubica
            }}
          >
            {buttonText}
          </MDBBtn>
        </MDBCol>
      ) : (
        <>
          {isLoggedIn ? (
            ""
          ) : (
            <Button
              className="cursor-pointer login-to-add-to-quote mt-4 mw-100"
              onClick={() => {
                toggleModal();

              }}
            >
              {"login or register for more"}
            </Button>
          )}
        </>
      )}
      <ModalWrapper
        toggleModal={toggleModal}
        modal={!isLoggedIn && modal}
        activeItem={activeItem}
        toggleTabs={toggleTabs}
      />
    </MDBRow>
  );
};

export default QuoteRequestWishlistButton;

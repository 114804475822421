import React, { useState, useEffect } from "react";
import {
  MDBCol,
  Link,
  MDBTypography,
  MDBBox,
  MDBModal,
  MDBModalHeader,
  MDBRow,
  MDBModalBody,
} from "mdbreact";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import placeholder from "app/assets/images/placeholder.png";
import Icon from "app/assets/icon/icon";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";
import Wishlist from "app/layout/wishlist";
import Button from "app/core/components/button/button";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const SingleProduct = ({
  image,
  name,
  sku,
  url,
  price,
  product,
  isWishlist = false,
  projectName,
  filteringQtyGrouped,
  availabilityGroup
}) => {
  const { isLoggedIn, customer } = useCustomer();
  const isMobile = useMediaQuery({ maxWidth: 991.98 });
  const [
    addToWishlist,
    removeFromWishlist,
    fetchWishlistData,
    getWishlistItems,
    moveItemToWishlist,
    editWishlist,
    wishlist,
    myWishlists,
    createWishlist,
    deleteWishlist,
    checkEmail,
  ] = useWishlist();
  const {
    data: { wishlistItems },
  } = wishlist;

  const [isFavorite, setIsFavorite] = useState(false);
  const [wishlistMOdal, setWishlistMOdal] = useState(false);
  const history = useHistory();

  let grouped = product?.items;
  if(grouped) {
    grouped.find((value, index) => {
      if(value.product.ark_closeout === 1){
       return  product.ark_closeout = 1
      }else {
        return product.ark_closeout
      }


    })
  }



  useEffect(() => {
    if (wishlistItems) {
      setIsFavorite(
        wishlistItems[wishlistItems.length - 1]?.items?.find(
          (favorite) => favorite?.product?.sku === sku
        )
      );
    }
  }, [wishlistItems]);

  if (sku === undefined ) return <></>;
  return (
    <>
      <MDBCol
        className={`single-product justify-content-between text-center p-0 pb-4`}
      >
        {!isWishlist && (
          <Wishlist
            customer={customer}
            login={isLoggedIn}
            product={product}
            fetchArr={wishlistItems}
            isFavorite={isFavorite}
            setIsFavorite={setIsFavorite}
          >
            <Icon
              icon={isFavorite ? "heartFull" : "heartPdp"}
              width={33}
              height={45}
              className={`mr-2 heart`}
              style={{
                minWidth: "33px",
                zIndex: "5",
                cursor: "pointer",
                position: "absolute",
                right: "15px",
              }}
            />
          </Wishlist>
        )}
        {/*{console.log("proooooooooooooooooooob=========================>",product.ark_closeout)}*/}
        {/*{console.log("proooooooooooooooooooob=========================>",product.sku)}*/}
        {/* <div className="favorite pointer position-absolute mt-4 pt-2 mr-3"
        style={{ top: "2%", right: "3%", zIndex: "2" }}>
      </div> */}
        {/* <div
        // onClick={(e) => handleFavorite(e, sku)}
        // onKeyUp={createEnterHandler(handleFavorite)}
        tabIndex={0}
        role="button"
        className="favorite pointer position-absolute mt-4 pt-2 mr-3"
        style={{ top: "2%", right: "3%", zIndex: "2" }}
      >
      </div> */}
        <MDBBox className={`flex-1`}>




          { product.custitem_closeout &&
          product.custitem_closeout !== null &&
          product.custitem_closeout !== 0 &&
          product.custitem_closeout !== "0" ?
            (
            <div className={"closeout-item"}>
              Limited Inventory
            </div>
            ): (<></>)

          }
          {   product.ark_closeout === 1 ?
              (
                  <div className={"closeout-item"}>
                    Clearance
                  </div>
              ): (<></>)

          }


          <Link
            to={(location) => {
              if (
                location.pathname !== "/customer/wishlist/" &&
                !location.pathname.includes("customer/wishlist/")
              ) {
                return location.pathname + `/${url}`;
              } else {
                return `/${url}`;
              }
            }}
          >
            <img
              src={image?.url || placeholder}
              className={`img-fluid`}
              alt="single-product"
            />
          </Link>
        </MDBBox>
        <Link
          to={(location) => {
            return location.pathname + `/${url}`;
          }}
          className={`flex-1 d-flex justify-content-between flex-column`}
        >
          <MDBCol className={`d-flex flex-1 p-0 justify-content-center align-items-center`} >
            <MDBTypography tag="h2" className="mt-3 mb-4 d-inline">
              {name}
            </MDBTypography>
          </MDBCol>
          <MDBCol className={`flex-1 p-0 justify-content-center d-flex align-items-center`} >
            <MDBTypography tag="p">SKU {sku}</MDBTypography>
            {isLoggedIn && price && (
              <MDBTypography tag="p">${price}</MDBTypography>
            )}
          </MDBCol>
        </Link>

        {

          /**************looking for children of grouped products******************/
          /*************** passed to filteringQtyGrouped the lowest checked amount***************/
          /*************** passed to availabilityGroup variable passed to filter***************/
          /**************end of looking for children of grouped products******************/

          product?.items ? (
              product?.items.map((simpleItem) => {

                var boolark_web_available_1 = availabilityGroup?.ark_web_available_1
                var boolark_web_available_2 = availabilityGroup?.ark_web_available_2
                var boolark_web_available_4 = availabilityGroup?.ark_web_available_4
                var boolark_web_available_6 = availabilityGroup?.ark_web_available_6
                var boolark_web_available_14 = availabilityGroup?.ark_web_available_14
                var boolark_closeout = availabilityGroup?.ark_closeout
                var inSimpleProduct = simpleItem.product;


               return (
                   <>
                     {
                         (filteringQtyGrouped && filteringQtyGrouped !== 1 && (
                             inSimpleProduct.ark_web_available_1 >= filteringQtyGrouped ||
                             inSimpleProduct.ark_web_available_2 >= filteringQtyGrouped ||
                             inSimpleProduct.ark_web_available_4 >= filteringQtyGrouped ||
                             inSimpleProduct.ark_web_available_6 >= filteringQtyGrouped ||
                             inSimpleProduct.ark_web_available_14 >= filteringQtyGrouped
                         ))  && (
                     <>
                       <span><b>SKU:</b> {inSimpleProduct.sku}</span>
                     </>
                     ) }
                   </>

               )

              })
          ): (
              <></>
          )
        }

        {isWishlist && (
          <div className="wishlist-part d-flex justify-content-center">
            {isMobile ? (
              <div className="quote-holder">
                <Icon
                  icon={"quote"}
                  width={35}
                  height={35}
                  className={`cursor`}
                  onClick={() => setWishlistMOdal(true)}
                />
              </div>
            ) : (
              <Button onClick={() => history.push(`/${url}`)}>
                {isWishlist ? "Add to quote request" : "Add to order request"}
              </Button>
            )}
            <Icon
              icon={"trashSmall"}
              width={25}
              height={25}
              className={`mr-2 cursor`}
              onClick={() => setWishlistMOdal(true)}
            />
          </div>
        )}
      </MDBCol>
      <MDBModal
        isOpen={wishlistMOdal}
        toggle={(e) => {
          setWishlistMOdal(!wishlistMOdal);
        }}
        className={"wishlist-modal modal-wrapper"}
        centered
      >
        <MDBModalHeader>
          <div>
            <p className="text-center">Remove Item</p>
            <MDBBox className="close-btn">
              <Icon
                icon="closeIcon"
                onClick={() => {
                  setWishlistMOdal(!wishlistMOdal);
                }}
              />
            </MDBBox>
          </div>
        </MDBModalHeader>
        <MDBModalBody className="d-flex w-100 flex-column">
          <MDBRow className="modal-title-wrapper align-items-center">
            <MDBCol size="12" className="mb-3 mt-5 text-center">
              <h3 className="mb-5">{`Are you sure you want to remove this product ?`}</h3>
            </MDBCol>
          </MDBRow>
          <div className="d-flex w-100">
            <Button
              className={"w-100 cancel"}
              onClick={(e) => {
                setWishlistMOdal(!wishlistMOdal);
              }}
            >
              CANCEL
            </Button>
            <Button
              className={"w-100 confirm"}
              onClick={async (e) => {
                await removeFromWishlist({
                  wishlist: projectName,
                  skus: sku,
                });
                try {
                  setWishlistMOdal(!wishlistMOdal);
                  const projectIndex = wishlistItems.findIndex(
                    (wishlist) => wishlist.name === projectName
                  );
                  wishlistItems[projectIndex].items = wishlistItems[
                    projectIndex
                  ].items.filter(({ product }) => product.sku !== sku);
                  //  console.log(wishlistItems[projectIndex].items);
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              CONFIRM
            </Button>
          </div>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default SingleProduct;

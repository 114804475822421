import React, { useEffect } from "react";
import { MDBBox } from "mdbreact";
import { withRouter } from "react-router-dom";
import { PageParser } from "core/components/cmsBlock/Parser";
import useCustomer from "core/state/hooks/customerHooks/useCustomer";
import Breadcrumbs from "app/layout/breadcrumbs";
import SeoHelmet from "app/layout/seoHelmet";
import { transform } from "./functions";

const CmsPage = (props) => {
  let { id, data, isCached, url, addQueryData, history } = props;

  let cmsPage =
    typeof props.data !== "undefined" &&
    typeof props.data.cmsPage !== "undefined"
      ? props.data.cmsPage
      : props.data;
  const [, isLoggedIn] = useCustomer();
  let token = isLoggedIn();

  if (props.isCached) cmsPage = props.isCached.cmsPage;

  useEffect(() => {
    if (!isCached && typeof url !== "undefined") {
      addQueryData(data.cmsPage, url, id);
    }
  }, [isCached, data, url, id, addQueryData]);

  if (typeof cmsPage === "undefined") {
    return "undefined";
  }

  if (!props.dangHtml) {
    return (
      <div
        itemScope
        itemType="http://schema.org/WebPageElement"
        itemProp={`mainContentOfPage`}
      >
        <meta itemProp={`cssSelector`} content={`.title`} />
        {data.title && <SeoHelmet title={data.title} url={data.url_key} />}
        <MDBBox className={`page-main-content page-${props.id}`}>
          <MDBBox id={"cmspage-" + props.id}>
            {typeof props.breadcrumbs === "undefined" ? <Breadcrumbs /> : <></>}
            <div itemProp={`text`}>
              {props.title && (
                <MDBBox tag="h3">
                  <span className={`title`} itemProp={`headline`}>
                    {cmsPage.title}
                  </span>
                </MDBBox>
              )}

              <PageParser
                {...props}
                data={{ ...cmsPage }}
                transform={(node, index) =>
                  transform(node, index, token, history)
                }
              />
            </div>
          </MDBBox>
        </MDBBox>
      </div>
    );
  }
  return (
    <div
      itemScope
      itemType="http://schema.org/WebPageElement"
      itemProp={`mainContentOfPage`}
    >
      <div itemProp={`text`}>
        <MDBBox dangerouslySetInnerHTML={{ __html: cmsPage.content.replaceAll("&lt;", "<").replaceAll("&gt;", ">") }} />
      </div>
    </div>
  );
};

export default withRouter(CmsPage);

import React, {memo, useEffect} from "react";
import PropTypes from "prop-types";
import { isEqual, isUndefined } from "lodash";
import themeConfiguration from "config/themeConfiguration";
import Helmet from "react-helmet";
import ReactGa from "react-ga4";
//import ReactGa as ReactGTA from "react-ga";

// const TRACKING_ID_BEFORE = "GTM-TG5BVHB";
// ReactGa.initialize(TRACKING_ID_BEFORE)// GOOGLE TAG MANAGER
const TRACKING_GTM = "GTM-TG5BVHB"
const TRACKING_ID_live = "G-VWFVG3SK64"; // OUR_TRACKING_ID
const TRACKING_ID_dev = "G-GRXER2X3BQ"; // OUR_TRACKING_ID
try {
    if(themeConfiguration.magento_url === "https://primalightingm2.joladev3.com/") {
        // console.log("Ljubica gatest");
        // console.log("1");
        ReactGa.initialize(TRACKING_GTM);
    }

    if(themeConfiguration.magento_url === "https://magento.primalighting.com/") {
        // console.log("Ljubica gatest");
        // console.log("2");

        ReactGa.initialize(TRACKING_GTM)
        //ReactGa.initialize(TRACKING_ID_live);
    }

} catch (e) {
    console.log(e)
}


const SeoHelmet = memo(


  ({ title, url, description, image, card, useAppName }) => {
      // console.log("Ljubica gatest");
      // console.log(themeConfiguration.magento_url);



    if (isUndefined(title) || isUndefined(url)) return <></>;
    let realTitle =
      title !== ""
        ? typeof useAppName !== "undefined" && useAppName === true
          ? title + " - " + themeConfiguration.name_homepage
          : title + " - " + themeConfiguration.name
        : themeConfiguration.name_homepage;
    let finalUrl = themeConfiguration.app_url + url;
    finalUrl = finalUrl.replace(".com//", ".com/");

      useEffect(() => {
        //  console.log("ljubica google analytisc");
          console.log(window.location.pathname + window.location.search);
          ReactGa.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
          //ReactGa.pageview();
      }, []);

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{realTitle}</title>
          <meta name="description" content={description} />

          {/** facebook */}
          <meta property="og:title" content={realTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
          <meta property="og:url" content={finalUrl} />

          {/** twitter */}
          <meta name="twitter:title" content={realTitle} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />
          <meta
            name="twitter:card"
            content={typeof card === "undefined" ? "summary_large_image" : card}
          />

          <link rel="canonical" href={finalUrl} />
        </Helmet>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

SeoHelmet.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

export default SeoHelmet;
